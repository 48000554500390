<template>
  <div style="height: 100%; display: grid">
    <center>
      <div id="button_div2">
        <button
          id="old_report_btn"
          type="button"
          class="btn btn-dark"
          @click.prevent="oldReport"
        >
          <i
            style="float: left; padding-top: 3px"
            class="fa fa-angle-double-left"
          ></i>

          Basic Report
        </button>

        <fieldset class="scheduler-border2">
          <legend
            class="scheduler-border2"
            style="color: #999999; text-align: left"
          >
            Selected Filters
          </legend>
          <div style="display: flex; flex-wrap: wrap; padding: 5px">
            <div id="filtre_div" v-if="platform_filter != null">
              <p>Platform : {{ platform_filter }}</p>
            </div>
            <div id="filtre_div" v-if="publisher_country_filter != null">
              <p>Publisher country : {{ publisher_country_filter }}</p>
            </div>
            <div id="filtre_div" v-if="category_filter != null">
              <p>Game category : {{ category_filter }}</p>
            </div>
            <div id="filtre_div" v-if="last_updated_filter != null">
              <p>Last updated : {{ last_updated_filter }}</p>
            </div>
            <div id="filtre_div" v-if="arpdau_filter != null">
              <p>ARPDAU : {{ arpdau_filter }}</p>
            </div>
            <div id="filtre_div" v-if="rpd_filter != null">
              <p>RPD : {{ rpd_filter }}</p>
            </div>
            <div id="filtre_div" v-if="growth_rate_filter != null">
              <p>Growth rate : {{ growth_rate_filter }}</p>
            </div>
            <div id="filtre_div" v-if="d1_filter != null">
              <p>D1 : {{ d1_filter }}</p>
            </div>
            <div id="filtre_div" v-if="d7_filter != null">
              <p>D7 : {{ d7_filter }}</p>
            </div>
            <div id="filtre_div" v-if="d30_filter != null">
              <p>D30 : {{ d30_filter }}</p>
            </div>
            <div id="filtre_div" v-if="d60_filter != null">
              <p>D60 : {{ d60_filter }}</p>
            </div>
            <div id="filtre_div" v-if="is_ua_active_filter != null">
              <p>Is UA Active : {{ is_ua_active_filter }}</p>
            </div>
          </div>
        </fieldset>
      </div>
    </center>

    <center>
      <div id="grid_div" :style="{ height: heightDiv + 'px' }">
        <ag-grid-vue
          :style="{ width, height }"
          class="ag-theme-alpine"
          id="myGrid"
          :columnDefs="columnDefs"
          :gridOptions="gridOptions"
          :rowData="rowData"
          :defaultColDef="defaultColDef"
          :columnTypes="columnTypes"
        ></ag-grid-vue>
      </div>
    </center>
  </div>
</template>
<script>
import "ag-grid-community/dist/styles/ag-grid.css";
import "ag-grid-community/dist/styles/ag-theme-alpine.css";
import { AgGridVue } from "ag-grid-vue";
import axios from "axios";
import CustomDateComponent from "./CustomDateComponent";

export default {
  title: "POW Market Analyzer - Advanced Report",
  data() {
    return {
      width: "100%",
      height: "100%",
      heightDiv: Number(window.innerHeight - 265),
      gridOptions: null,
      gridApi: null,
      columnApi: null,
      columnDefs: null,
      defaultColDef: null,
      defaultColGroupDef: null,
      columnTypes: null,
      rowData: null,
      report_id: this.$route.query.report_id,
      filterOptions: null,
      //filters: JSON.parse(localStorage.filters)[0],
      filters: null,
      button_div: null,
      category_filter: null,
      arpdau_filter: null,
      rpd_filter: null,
      platform_filter: null,
      publisher_country_filter: null,
      game_category_filter: null,
      last_updated_filter: null,
      growth_rate_filter: null,
      d1_filter: null,
      d7_filter: null,
      d30_filter: null,
      d60_filter: null,
      is_ua_active_filter: null,
      frameworkComponents: null,
    };
  },
  components: {
    AgGridVue,
  },
  created() {
    localStorage.component = "Advanced Report";
  },
  mounted() {
    this.gridApi = this.gridOptions.api;
    this.gridColumnApi = this.gridOptions.columnApi;
  },

  beforeMount() {
    window.addEventListener("resize", this.reportWindowSize);

    (this.filterOptions = [
      {
        displayKey: "customEquals",
        displayName: "Equals",
        test: (filterValue, cellValue) => cellValue == filterValue,
      },
      {
        displayKey: "customNotEquals",
        displayName: "Not equal",
        test: (filterValue, cellValue) => cellValue != filterValue,
      },

      {
        displayKey: "lessThan",
        displayName: "Less than",
        test: (filterValue, cellValue) => cellValue < filterValue,
      },
      {
        displayKey: "lessThanOrEquals",
        displayName: "Less than or equals",
        test: (filterValue, cellValue) =>
          cellValue == filterValue || cellValue < filterValue,
      },
      "greaterThan",
      {
        displayKey: "greaterThanOrEquals",
        displayName: "Greater than or equals",
        test: (filterValue, cellValue) =>
          cellValue == filterValue || cellValue > filterValue,
      },
      "inRange",
    ]),
      (this.frameworkComponents = { agDateInput: CustomDateComponent });

    this.gridOptions = {
      groupDefaultExpanded: 1,
      suppressAggFuncInHeader: true,
      animateRows: true,
      onCellValueChanged: this.onCellValueChanged,
      frameworkComponents: this.frameworkComponents,
    };

    axios
      .get(
        "https://pow.games:5000/getreport?report_id=" + this.report_id
      )
      .then((response) => {
        console.log(response);
        this.rowData = response.data.apps;
        this.filters = response.data.filters;
        this.platform_filter = this.filters.platform;
        this.publisher_country_filter = this.filters.publisher_country;
        this.last_updated_filter = this.filters.last_updated;
        this.growth_rate_filter = this.filters.growth_rate;
        this.d1_filter = this.filters.d1;
        this.d7_filter = this.filters.d7;
        this.d30_filter = this.filters.d30;
        this.d60_filter = this.filters.d60;
        this.is_ua_active_filter = this.filters.is_ua_active;
        if (this.filters.game_category != null) {
          this.category_filter = this.filters.game_category.join(", ");
        }
        if (this.filters.arpdau != null) {
          this.arpdau_filter = this.filters.arpdau.join(", ");
        }
        if (this.filters.rpd != null) {
          this.rpd_filter = this.filters.rpd.join(", ");
        }

        console.log(this.rowData);
        console.log(this.filters);
      });

    this.columnDefs = [
      {
        headerName: "",
        field: "icon_url",
        width: 70,
        pinned: true,
        cellRenderer: this.iconCellRenderer,
      },
      {
        headerName: "Name",
        field: "humanized_name",
        width: 230,
        pinned: true,
        cellRenderer: this.nameCellRenderer,
        filter: "agTextColumnFilter",
        floatingFilter: true,
      },
      {
        headerName: "",
        width: 70,
        pinned: true,
        cellRenderer: this.sensorTowerRenderer,
      },
      {
        headerName: "Average Users",
        field: "average_users",
        sortable: true,
        type: "numberColumn",
        filter: "agNumberColumnFilter",
        floatingFilter: true,
        sortingOrder: ["asc", "desc"],
      },
      {
        headerName: "Prior Avg Users",
        field: "prior_average_users",
        sortable: true,
        type: "numberColumn",
        filter: "agNumberColumnFilter",
        floatingFilter: true,
        sortingOrder: ["asc", "desc"],
      },
      {
        headerName: "Growth",
        field: "growth",
        width: 120,
        type: "numberColumn",
        sortable: true,
        filter: "agNumberColumnFilter",
        floatingFilter: true,
        sortingOrder: ["asc", "desc"],
      },
      {
        headerName: "Growth Percent",
        field: "growth_percent",
        type: "numberColumn",
        sortable: true,
        valueFormatter: (params) => this.growthPercentRenderer(params),
        cellRenderer: this.percentBarRenderer,
        filter: "agNumberColumnFilter",
        floatingFilter: true,
        filterParams: {
          valueGetter: (params) => this.growthPercentRenderer(params),
          filterOptions: this.filterOptions,
        },
        sort: "desc",
        sortingOrder: ["asc", "desc"],
      },
      {
        headerName: "Revenue",
        field: "revenue",
        type: "numberColumn",
        width: 150,
        sortable: true,
        valueGetter: (params) => this.revenueRenderer(params),
        cellRenderer: this.revenueCellRenderer,
        filter: "agNumberColumnFilter",
        floatingFilter: true,
        filterParams: {
          valueGetter: (params) => this.revenueRenderer(params),
          filterOptions: this.filterOptions,
        },
        sortingOrder: ["asc", "desc"],
      },
      {
        headerName: "RPD",
        field: "rpd",
        type: "numberColumn",
        width: 130,
        sortable: true,
        floatingFilter: true,
        valueGetter: (params) => this.rpdRenderer(params),
        cellRenderer: this.rpdCellRenderer,
        filter: "agNumberColumnFilter",
        filterParams: {
          valueGetter: (params) => this.rpdRenderer(params),
          filterOptions: this.filterOptions,
        },
        sortingOrder: ["asc", "desc"],
      },
      {
        headerName: "ARPDAU",
        field: "arpdau",
        type: "numberColumn",
        width: 150,
        sortable: true,
        floatingFilter: true,
        valueGetter: (params) => this.arpdauRenderer(params),
        cellRenderer: this.arpdaucellRenderer,
        filter: "agNumberColumnFilter",
        filterParams: {
          valueGetter: (params) => this.arpdauRenderer(params),
          filterOptions: this.filterOptions,
        },
        sortingOrder: ["asc", "desc"],
      },
      {
        headerName: "Publisher Country",
        field: "publisher_country",
        filter: "agTextColumnFilter",
        floatingFilter: true,
      },
      {
        headerName: "Active UA",
        field: "active_ua",
        width: 150,
        filter: "agTextColumnFilter",
        floatingFilter: true,
      },
      {
        headerName: "Last Updated",
        field: "last_updated",
        width: 200,
        filter: "agDateColumnFilter",
        floatingFilter: true,
        sortable: true,
        filterParams: {
          debounceMs: 500,
          suppressAndOrCondition: true,
          browserDatePicker: true,
          comparator: this.dateFilter,
        },
        sortingOrder: ["asc", "desc"],
      },
      {
        headerName: "Release Date",
        field: "release_date",
        width: 200,
        filter: "agDateColumnFilter",
        floatingFilter: true,
        sortable: true,
        filterParams: {
          debounceMs: 500,
          suppressAndOrCondition: true,
          browserDatePicker: true,
          comparator: this.dateFilter,
        },
        sortingOrder: ["asc", "desc"],
      },

      {
        headerName: "Retention",
        marryChildren: true,
        children: [
          {
            headerName: "D1",
            field: "d1",
            width: 120,
            type: "numberColumn",
            sortable: true,
            filter: "agNumberColumnFilter",
            floatingFilter: true,
            valueFormatter: (params) => this.d1Renderer(params),
            filterParams: {
              valueGetter: (params) => this.d1Renderer(params),
              filterOptions: this.filterOptions,
            },
            sortingOrder: ["asc", "desc"],
          },
          {
            headerName: "D7",
            field: "d7",
            width: 120,
            type: "numberColumn",
            sortable: true,
            filter: "agNumberColumnFilter",
            floatingFilter: true,
            suppressAndOrCondition: true,
            valueFormatter: (params) => this.d7Renderer(params),
            filterParams: {
              valueGetter: (params) => this.d7Renderer(params),
              filterOptions: this.filterOptions,
            },
            sortingOrder: ["asc", "desc"],
          },
          {
            headerName: "D30",
            field: "d30",
            width: 120,
            type: "numberColumn",
            sortable: true,
            filter: "agNumberColumnFilter",
            floatingFilter: true,
            valueFormatter: (params) => this.d30Renderer(params),
            filterParams: {
              valueGetter: (params) => this.d30Renderer(params),
              filterOptions: this.filterOptions,
            },
            sortingOrder: ["asc", "desc"],
          },
          {
            headerName: "D60",
            field: "d60",
            width: 120,
            type: "numberColumn",
            sortable: true,
            filter: "agNumberColumnFilter",
            valueFormatter: (params) => this.d60Renderer(params),
            filterParams: {
              valueGetter: (params) => this.d60Renderer(params),
              filterOptions: this.filterOptions,
            },
            floatingFilter: true,
            sortingOrder: ["asc", "desc"],
          },
        ],
      },
    ];

    this.defaultColDef = {
      resizable: true,
    };
  },
  methods: {
    reportWindowSize() {
      var height = document.getElementById("button_div2").offsetHeight + 120;
      this.heightDiv = Number(window.innerHeight - height);
    },
    dateFilter(filterLocalDateAtMidnight, cellValue) {
      if (cellValue == null) {
        return 0;
      }
      var date = filterLocalDateAtMidnight;
      var dateParts = cellValue.split("/");
      var year = Number(dateParts[0]);
      var month = Number(dateParts[1]) - 1;
      var day = ("0" + date.getDate()).slice(-2);
      var cellDate = new Date(year, month, day);

      if (cellDate < filterLocalDateAtMidnight) {
        return -1;
      } else if (cellDate > filterLocalDateAtMidnight) {
        return 1;
      } else {
        return 0;
      }
    },
    onCellValueChanged(params) {
      var changedData = [params.data];
      params.api.applyTransaction({ update: changedData });
    },
    iconCellRenderer(params) {
      let icon =
        "<img border='0'  width='32' height='32' style='margin-top: 2px; border-radius='1rem'' src='" +
        params.value +
        "'>";
      return icon;
    },
    sensorTowerRenderer(params) {
      let platform;
      let publisher_name = this.punctuationlessName(params.data.publisher_name);
      let app_name = this.punctuationlessName(params.data.humanized_name);
      let app_pkg = params.data.app_pkg;
      if (app_pkg.toString().includes(".")) {
        platform = "android";
      } else {
        platform = "ios";
      }

      let st_link =
        "https://sensortower.com/" +
        platform +
        "/US/" +
        publisher_name +
        "/app/" +
        app_name +
        "/" +
        app_pkg +
        "/" +
        "overview";
      let tower_icon =
        "<center><a target='_blank' href='" +
        st_link +
        "'><div style='height:20px;'><img border='0' style='margin-top: 10px;' src='https://res-1.cloudinary.com/crunchbase-production/image/upload/c_lpad,f_auto,q_auto:eco/rctxu1a4cz77emtax4st'></a></center></div>";
      return tower_icon;
    },
    punctuationlessName(name) {
      var punctuationless = name.replace(/[.,/#!$?'%^&*;:{}=\-_`~()]/g, "");
      var finalName = punctuationless.replace(/\s{2,}/g, " ");
      return finalName;
    },
    nameCellRenderer(params) {
      var app_pkg = params.data.app_pkg;
      let appLink;
      var app_name;
      if (params.value == null) {
        app_name = params.data.name;
        console.log(app_name);
      } else {
        app_name = params.value;
      }

      if (app_pkg != null) {
        if (app_pkg.toString().includes(".")) {
          appLink =
            "<a target='_blank' href='https://play.google.com/store/apps/details?id=" +
            app_pkg +
            "'>" +
            app_name +
            "</a>";
        } else {
          appLink =
            "<a target='_blank' href='https://apps.apple.com/US/app/id" +
            app_pkg +
            "'>" +
            app_name +
            "</a>";
        }
      } else {
        console.log("app pkg is null");
      }

      let publisher_name =
        "<p style='margin-bottom: 0px;margin-top:0px'>" +
        params.data.publisher_name +
        "</p>";
      return appLink + publisher_name;
    },
    growthPercentRenderer(params) {
      return Number(params.data.growth_percent).toFixed(2);
    },
    revenueRenderer(params) {
      var newParams = params.data.revenue.replace("$", "");
      return Number(newParams);
    },
    revenueCellRenderer(params) {
      return "$" + params.value;
    },
    rpdRenderer(params) {
      var newRpd = params.data.rpd.replace("$", "");
      return Number(newRpd);
    },
    rpdCellRenderer(params) {
      return "$" + params.value;
    },
    arpdauRenderer(params) {
      var newArpdau;
      if (params.data.arpdau.includes("$")) {
        newArpdau = Number(params.data.arpdau.replace("$", ""));
      } else {
        newArpdau = -1;
      }
      return newArpdau;
    },
    arpdaucellRenderer(params) {
      if (params.value == -1) {
        return "Disabled Small App";
      } else {
        return "$" + params.value;
      }
    },
    d1Renderer(params) {
      return parseFloat(params.data.d1).toFixed(2);
    },
    d7Renderer(params) {
      return parseFloat(params.data.d7).toFixed(2);
    },
    d30Renderer(params) {
      return parseFloat(params.data.d30).toFixed(2);
    },
    d60Renderer(params) {
      return parseFloat(params.data.d60).toFixed(2);
    },
    percentBarRenderer(params) {
      let value = params.value.toFixed(2);

      let eDivPercentBar = document.createElement("div");
      eDivPercentBar.className = "div-percent-bar";
      eDivPercentBar.style.width = value + "%";
      if (value < 20) {
        eDivPercentBar.style.backgroundColor = "#f55d51";
      } else if (value < 60) {
        eDivPercentBar.style.backgroundColor = "#ffb300";
      } else {
        eDivPercentBar.style.backgroundColor = "#82d249";
      }

      let eValue = document.createElement("div");
      eValue.className = "div-percent-value";
      eValue.innerHTML = value + "%";

      let eOuterDiv = document.createElement("div");
      eOuterDiv.className = "div-outer-div";
      eOuterDiv.appendChild(eValue);
      eOuterDiv.appendChild(eDivPercentBar);

      return eOuterDiv;
    },
    oldReport() {
      this.$router.push("/report?report_id=" + this.report_id);
    },
  },
};
</script>
<style>
.ag-theme-alpine .ag-row {
  font-size: 12px;
}
.ag-theme-alpine .ag-cell,
.ag-theme-alpine .ag-full-width-row .ag-cell-wrapper.ag-row-group {
  line-height: normal;
}
.ag-header-group-cell-with-group {
  text-align: center;
}
.ag-theme-alpine .ag-header-cell,
.ag-theme-alpine .ag-header-group-cell {
  left: 2550px;
}
.ag-root-wrapper-body.ag-layout-normal {
  height: 100%;
}
.outer-div {
  height: 100%;
  display: flex;
  flex-direction: column;
}

.button-bar {
  margin-bottom: 1rem;
}

.grid-wrapper {
  flex: 1 1 auto;
}
.ag-theme-alpine .ag-row {
  text-align: left;
}
.ag-theme-alpine img {
  border-radius: 1rem;
}
#myGrid {
  margin-bottom: 10px;
}
.div-percent-bar {
  display: inline-block;
  height: 100%;
  position: relative;
  z-index: 0;
}

.div-percent-value {
  position: absolute;
  padding-left: 4px;
  font-weight: bold;
  font-size: 13px;
  z-index: 100;
}

.div-outer-div {
  display: inline-block;
  height: 100%;
  width: 100%;
}
#old_report_btn {
  width: 160px;
  height: 40px;
  border-radius: 5rem;
  font-size: 16px;
  margin-top: 10px;
  margin-bottom: 10px;
  margin-right: 10px;
  float: left;
  outline: none;
  font-family: "NotoSans-Regular";
  padding: 0.375rem 0.75rem;
}
#button_div2 {
  width: 95%;
}
#grid_div {
  flex: 1 1 0px;
  width: 95%;
  box-sizing: border-box;
}
.ag-cell-last-left-pinned .ag-cell-value {
  text-align: center;
}
#filtre_div {
  border: 0.1px solid #ccddff;
  box-shadow: 0 1px 1px rgba(0, 0, 0, 0.4);
  border-radius: 1rem;
  box-sizing: border-box;
  text-align: center;
  vertical-align: middle;
  padding: 0 0.75rem;
  margin-top: 10px;
  margin-left: 5px;
  font-size: 12px;
}
#filtre_div p {
  margin: revert;
}
fieldset.scheduler-border2 {
  border: 1px solid #ddd !important;
  padding: 0 1.4em 1.4em 1.4em !important;
  margin: 0 10px 1.5em 10px !important;
  border-radius: 1rem;
  margin-left: 10px;
}

legend.scheduler-border2 {
  font-size: 1.2em !important;
  text-align: left !important;
  width: auto;
  padding: 0 10px;
  border-bottom: none;
}
img {
  max-width: 100%;
  max-height: 100%;
}
.custom-date-filter a {
  position: absolute;
  right: 20px;
  color: rgba(0, 0, 0, 0.54);
  cursor: pointer;
}
.custom-date-filter:after {
  position: absolute;
  content: "\f073";
  right: 5px;
  pointer-events: none;
  color: rgba(0, 0, 0, 0.54);
  display: inline-block;
  font-family: FontAwesome;
  font-style: normal;
  font-weight: normal;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
.flatpickr-input {
  border: 1px solid #babfc7;
  border-radius: 3px;
}
</style>