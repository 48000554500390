<template>
  <div id="app">
    <router-view></router-view>
  </div>
</template>

<script>
export default {
  name: "App",
};
</script>

<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #2c3e50;
}
@font-face {
  font-family: NotoSans-Bold;
  src: local(NotoSans-Bold),
    url("./assets/fonts/NotoSans-Bold.ttf") format("truetype");
}
@font-face {
  font-family: NotoSans-Regular;
  src: local(NotoSans-Regular),
    url("./assets/fonts/NotoSans-Regular.ttf") format("truetype");
}
</style>
