<template>
  <div class="hello">
    <p id="page_title">FILTERS</p>
    <center>
      <fieldset class="scheduler-border">
        <legend class="scheduler-border" style="color: #999999">
          APP INFO
        </legend>
        <div>
          <div style="text-align: left" class="col-sm-12 col-md-12 col-lg-2">
            <label>* Platform </label>
            <multiselect
              placeholder="Platform"
              v-model="value_platform"
              :options="options_platform"
              :searchable="false"
              :close-on-select="false"
              :show-labels="false"
              :disabled="is_disabled"
            >
            </multiselect>
            <p :class="is_platform" style="color: red">
              * Platform filter is required field
            </p>
          </div>

          <div style="text-align: left" class="col-sm-12 col-md-12 col-lg-3">
            <label>Publisher Country </label>
            <multiselect
              placeholder="Publisher Country"
              v-model="value_country"
              :options="country_list.values"
              :searchable="true"
              :show-labels="false"
              :disabled="is_disabled"
            >
            </multiselect>
          </div>
          <div style="text-align: left" class="col-sm-12 col-md-12 col-lg-3">
            <label>Last Updated </label>
            <multiselect
              placeholder="Last Updated"
              v-model="value_last_updated"
              :options="last_updated_options.values"
              :searchable="false"
              :show-labels="false"
              :disabled="is_disabled"
            >
            </multiselect>
          </div>

          <div
            id="growth_rate_input"
            class="form-group col-sm-12 col-md-12 col-lg-3 custom_input_div"
          >
            <label class="control-label" style="float: left; display: flex"
              >Growth Rate (vs previous month)
            </label>

            <input
              id="growth_rate"
              v-model="value_growthrate"
              type="number"
              class="form-control custom_input"
              placeholder=""
              value
              :disabled="is_disabled"
            />
            <span>%</span>
          </div>
        </div>
        <div style="margin-top: 100px">
          <div style="text-align: left" class="col-sm-12 col-md-12 col-lg-2">
            <label>Active UA </label>
            <multiselect
              placeholder="Active UA"
              v-model="value_ua"
              :options="options_ua"
              :searchable="false"
              :close-on-select="false"
              :show-labels="false"
              :disabled="is_disabled"
            >
            </multiselect>
          </div>
          <div style="text-align: left" class="col-sm-12 col-md-12 col-lg-3">
            <label>Game Category </label>
            <multiselect
              placeholder="Game Category"
              v-model="value_category"
              :options="game_category.values"
              :searchable="true"
              :show-labels="false"
              :disabled="is_disabled"
              :multiple="true"
            >
            </multiselect>
          </div>

          <div style="text-align: left" class="col-sm-12 col-md-12 col-lg-3">
            <label>RPD </label>
            <multiselect
              placeholder="RPD"
              v-model="value_rpd"
              :options="rpd_options.values"
              :searchable="false"
              :close-on-select="false"
              :show-labels="false"
              :disabled="is_disabled"
              :multiple="true"
            >
            </multiselect>
          </div>

          <div style="text-align: left" class="col-sm-12 col-md-12 col-lg-3">
            <label>ARPDAU </label>
            <multiselect
              placeholder="ARPDAU"
              v-model="value_arpdau"
              :options="arpdau_options.values"
              :searchable="false"
              :close-on-select="false"
              :show-labels="false"
              :disabled="is_disabled"
              :multiple="true"
            >
            </multiselect>
          </div>
        </div>
      </fieldset>
    </center>

    <center>
      <fieldset class="scheduler-border">
        <legend class="scheduler-border" style="color: #999999">
          RETENTION
        </legend>
        <div
          id="retentiond1_input"
          class="form-group col-sm-12 col-md-2 col-lg-2 custom_input_div"
        >
          <label class="control-label" style="float: left">D1</label>

          <input
            id="d1_input"
            v-model="value_d1retention"
            type="number"
            class="form-control custom_input"
            placeholder=""
            value
            :disabled="is_disabled"
          />
          <span>%</span>
        </div>
        <div
          id="retentiond7_input"
          class="form-group col-sm-12 col-md-2 col-lg-2 custom_input_div"
        >
          <label class="control-label" style="float: left">D7</label>

          <input
            id="d7_input"
            v-model="value_d7retention"
            type="number"
            class="form-control custom_input"
            placeholder=""
            value
            :disabled="is_disabled"
          />
          <span>%</span>
        </div>
        <div
          id="retentiond30_input"
          class="form-group col-sm-12 col-md-2 col-lg-2 custom_input_div"
        >
          <label class="control-label" style="float: left">D30 </label>

          <input
            id="d30_input"
            v-model="value_d30retention"
            type="number"
            class="form-control custom_input"
            placeholder=""
            value
            :disabled="is_disabled"
          />
          <span>%</span>
        </div>
        <div
          id="retentiond60_input"
          class="form-group col-sm-12 col-md-2 col-lg-2 custom_input_div"
        >
          <label class="control-label" style="float: left">D60</label>

          <input
            id="d60_input"
            v-model="value_d60retention"
            type="number"
            class="form-control custom_input"
            placeholder=""
            value
            :disabled="is_disabled"
          />
          <span>%</span>
        </div>
      </fieldset>
    </center>
    <center>
      <div id="button_div">
        <button
          id="get_data_button"
          type="button"
          class="btn btn-dark"
          @click.prevent="openProcessPopup"
        >
          Get Report
        </button>
      </div>
    </center>
    <div id="modal-container-process" class="hide">
      <div id="modal-dialog-process">
        <center>
          <div class="dialog-header">
            <sweetalert-icon icon="success" />
          </div>
          <div id="dialog-content" v-if="!infoStatus">
            <p style="margin: 46px; font-size: 12px">
              Your data is being processed. When the process is over, you will
              be notified with a notification.
            </p>
          </div>
          <div id="dialog-content2" v-else>
            <p
              id="filter_list"
              style="color: black; width: 350px; margin: 30px"
            >
              Filters json : {{ filters }}
            </p>
          </div>

          <button id="btn_ok" class="btn-dark" @click="closePopup">OK</button>
          <button id="btn_info" class="btn-info" @click="getFilterInfo">
            Info
          </button>
        </center>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import Multiselect from "vue-multiselect";
export default {
  title: "POW Market Analyzer - Filters",
  components: {
    Multiselect,
  },

  data() {
    return {
      is_disabled: false,
      game_category: {
        name: "Storefront Game Subcategory",
        values: [
          "Casino",
          "Music",
          "Family",
          "Casual",
          "Dice",
          "Role Playing",
          "Board",
          "Trivia",
          "Strategy",
          "Puzzle",
          "Educational",
          "Adventure",
          "Racing",
          "Word",
          "Sports",
          "Simulation",
          "Action",
          "Card",
          "Arcade",
        ],
      },
      value_ua: null,
      options_ua: ["True", "False"],
      value_last_updated: null,
      last_updated_options: {
        name: "Latest Update Days Ago",
        values: [
          "> 3 years",
          "~ 3 years",
          "~ 2 years",
          "~ 1 year",
          "~ 6 months",
          "~ 3 months",
          "~ 1 month",
          "~ 2 weeks",
          "~ 1 week",
        ],
      },
      value_arpdau: null,
      value_rpd: null,
      value_d1retention: null,
      value_d7retention: null,
      value_d30retention: null,
      value_d60retention: null,
      value_growthrate: null,
      value_platform: null,
      options_platform: ["IOS", "Android"],
      value_country: null,
      country_list: {
        name: "Publisher Country",
        values: [
          "China",
          "Belarus",
          "Poland",
          "Uruguay",
          "Belgium",
          "Hungary",
          "Iceland",
          "Vietnam",
          "El Salvador",
          "Curaçao",
          "Russia",
          "Argentina",
          "Estonia",
          "US",
          "Pakistan",
          "Philippines",
          "Oman",
          "Malta",
          "Turkey",
          "Nicaragua",
          "Belize",
          "Liechtenstein",
          "Kyrgyzstan",
          "Slovenia",
          "Cyprus",
          "Samoa",
          "Israel",
          "Venezuela",
          "Lithuania",
          "Hong Kong",
          "Mongolia",
          "Guam",
          "Germany",
          "Sweden",
          "Great Britain",
          "France",
          "Morocco",
          "Austria",
          "Portugal",
          "Slovakia",
          "Ukraine",
          "Serbia",
          "Japan",
          "Gibraltar",
          "Bangladesh",
          "Norway",
          "Antarctica",
          "Jordan",
          "Chile",
          "Georgia",
          "Ghana",
          "Nepal",
          "Guernsey",
          "Mali",
          "Cambodia",
          "India",
          "French Polynesia",
          "Montenegro",
          "Burkina Faso",
          "Trinidad and Tobago",
          "Luxembourg",
          "Brazil",
          "Iraq",
          "Sri Lanka",
          "Saudi Arabia",
          "Panama",
          "New Zealand",
          "South Korea",
          "Ecuador",
          "Seychelles",
          "Bolivia",
          "Latvia",
          "Cote D Ivoire",
          "Malaysia",
          "Barbados",
          "Macau",
          "Puerto Rico",
          "Finland",
          "Bosnia and Herzegovina",
          "Italy",
          "Virgin Islands (U.S.)",
          "Aruba",
          "San Marino",
          "Lebanon",
          "Moldova",
          "Colombia",
          "United Arab Emirates",
          "Bulgaria",
          "Croatia",
          "South Africa",
          "Australia",
          "Switzerland",
          "Peru",
          "Yemen",
          "Qatar",
          "Cayman Islands",
          "Myanmar",
          "The Bahamas",
          "Guatemala",
          "Paraguay",
          "Andorra",
          "Macedonia",
          "Costa Rica",
          "Greece",
          "Taiwan",
          "Algeria",
          "Czech Republic",
          "Spain",
          "Ireland",
          "Egypt",
          "New Caledonia",
          "Armenia",
          "Kuwait",
          "Nigeria",
          "Kenya",
          "Isle of Man",
          "Réunion",
          "Kazakhstan",
          "British Virgin Islands",
          "Martinique",
          "Thailand",
          "Azerbaijan",
          "Tunisia",
          "Dominican Republic",
          "Uganda",
          "Netherlands",
          "Indonesia",
          "Jersey",
          "Mexico",
          "Iran (Islamic Republic of)",
          "Jamaica",
          "Denmark",
          "Uzbekistan",
          "Tajikistan",
          "Canada",
          "Singapore",
          "Lesotho",
          "Mauritius",
          "Bahrain",
          "Albania",
          "Åland Islands",
          "Romania",
          "Tanzania",
          "Honduras",
          "Brunei",
          "Turks Caicos",
          "Benin",
          "Pitcairn",
          "Burundi",
          "Syrian Arab Republic",
          "Marshall Islands",
          "South Sudan",
          "Guinea-Bissau",
          "Congo",
          "Mozambique",
          "Gabon",
          "Ethiopia",
          "Niger",
          "Vatican",
          "Bermuda",
          "Tonga",
          "Chad",
          "Dominica",
          "Palestine, State of",
          "Cameroon",
          "Cook Islands",
          "Western Sahara",
          "Congo (Democratic Republic of the)",
          "Tokelau",
          "Palau",
          "Guadeloupe",
          "Zambia",
          "Zimbabwe",
          "Togo",
          "Antigua Barbuda",
          "Djibouti",
          "Sao Tome and Principe",
          "Saint Barthélemy",
          "Grenada",
          "Angola",
          "Rwanda",
          "Mayotte",
          "Turkmenistan",
          "British Indian Ocean Territory",
          "Faroe Islands",
          "Lao People's Democratic Republic",
          "Botswana",
          "Svalbard and Jan Mayen",
          "Swaziland",
          "Papua New Guinea",
          "Sudan",
          "Bhutan",
          "Guyana",
          "Guinea",
          "St Lucia",
          "American Samoa",
          "Sint Maarten (Dutch part)",
          "St Vincent The Grenadines",
          "Liberia",
          "Monaco",
          "Timor-Leste",
          "Senegal",
          "Greenland",
          "Cabo Verde",
          "Maldives",
          "Northern Mariana Islands",
          "Afghanistan",
          "Somalia",
          "Solomon Islands",
          "Korea (Democratic People's Republic of)",
          "Christmas Island",
          "Madagascar",
          "Cocos (Keeling) Islands",
          "Gambia",
          "Equatorial Guinea",
          "Sierra Leone",
          "Suriname",
          "Kosovo",
          "Malawi",
          "Comoros",
          "Fiji",
          "Namibia",
          "St Kitts Nevis",
          "French Guiana",
          "Montserrat",
          "Haiti",
          "Mauritania",
          "Cuba",
          "Anguilla",
          "Saint Martin (French part)",
          "Eritrea",
          "Libya",
          "Vanuatu",
        ],
      },
      value_category: null,
      value_release_ago: null,
      release_ago: {
        name: "Release Days Ago",
        values: [
          "~ 3 months",
          "~ 2 years",
          "~ 1 year",
          "~ 3 years",
          "~ 1 week",
          "> 3 years",
          "~ 6 months",
          "~ 2 weeks",
          "~ 1 month",
        ],
      },
      arpdau_options: {
        name: "ARPDAU (Last Month, US)",
        values: [
          "Disabled Small App",
          "$0.00",
          "$0.01",
          "$0.02",
          "$0.03",
          "$0.04",
          "$0.05",
          "$0.06",
          "$0.07",
          "$0.08",
          "$0.09",
          "$0.10 - $0.19",
          "$0.20 - $0.29",
          "$0.30 - $0.39",
          "$0.40 - $0.49",
          "$0.50 - $0.59",
          "$0.60 - $0.69",
          "$0.70 - $0.79",
          "$0.80 - $0.89",
          "$0.90 - $0.99",
          "$1.00 - $1.99",
          "$2.00 - $2.99",
          "$3.00 - $3.99",
          "$4.00 - $4.99",
          "$5.00 - $5.99",
          "$6.00 - $6.99",
          "$7.00 - $7.99",
          "$8.00 - $8.99",
          "$9.00 - $9.99",
          "$10.00+",
        ],
      },
      rpd_options: {
        name: "RPD (All Time, WW)",
        global: true,
        values: [
          "< $0.25",
          "$0.25 - $0.50",
          "$0.50 - $1.00",
          "$1.00 - $3.00",
          "$3.00 - $5.00",
          "$5.00 - $7.50",
          "$7.50 - $10.00",
          "$10.00 - $15.00",
          "$15.00 - $20.00",
          "> $20.00",
        ],
      },
      is_platform: "hidden",
      filters: null,
      is_busy: null,
      infoStatus: false,
    };
  },
  created() {
    localStorage.component = "Filters";
    this.isBusyPopup();
  },
  computed: {
    hidden_status_rpd() {
      if (this.value_rpd == "" || this.value_rpd == null) {
        console.log("rpd if" + this.value_rpd);
        return "fade";
      } else {
        console.log("rpd else" + this.value_rpd);
        return "none";
      }
    },
  },
  methods: {
    openPopup() {
      var process_dialog = document.getElementById("modal-container-process");
      process_dialog.classList.remove("hide");
    },
    closePopup() {
      var process_dialog = document.getElementById("modal-container-process");
      process_dialog.classList.add("hide");
      this.infoStatus = false;
      this.is_disabled = true;
      this.getIsBusy();
    },
    getFilterInfo() {
      this.infoStatus = !this.infoStatus;
    },
    openProcessPopup() {
      if (this.is_busy == "True") {
        this.isBusyPopup();
      } else {
        if (this.value_platform == null) {
          this.is_platform = "none";
        } else {
          this.getData();
          this.openPopup();
        }
      }
    },
    getIsBusy() {
      axios
        .get("https://pow.games:5000/isbusy", {
          headers: {},
        })
        .then((response) => {
          console.log(response);
          console.log(response.data);
          this.is_busy = response.data;
          console.log(this.is_busy);
        });
    },
    isBusyPopup() {
      this.getIsBusy();
      setTimeout(() => {
        if (this.is_busy != null) {
          clearTimeout();
          if (this.is_busy == "True") {
            this.$alert("Service is currently busy.", "", "warning").then(
              () => {
                this.is_disabled = true;
                console.log("Closed");
                this.isBusyPopup();
              }
            );
          } else {
            this.is_disabled = false;
            console.log(this.is_busy);
          }
        } else {
          console.log("null: " + this.is_busy);
        }
      }, 1000);
    },
    getData() {
      if (this.value_category != null && this.value_category.length == 0) {
        console.log("value category null");
        this.value_category = null;
      } else if (this.value_rpd != null && this.value_rpd.length == 0) {
        console.log("value rpd null");
        this.value_rpd = null;
      } else if (this.value_arpdau != null && this.value_arpdau.length == 0) {
        console.log("value arpdau null");
        this.value_arpdau = null;
      }

      var filtersArray = [
        {
          platform: this.value_platform,
          publisher_country: this.value_country,
          game_category: this.value_category,
          last_updated: this.value_last_updated,
          arpdau: this.value_arpdau,
          rpd: this.value_rpd,
          growth_rate: parseFloat(this.value_growthrate),
          d1: parseFloat(this.value_d1retention),
          d7: parseFloat(this.value_d7retention),
          d30: parseFloat(this.value_d30retention),
          d60: parseFloat(this.value_d60retention),
          is_ua_active: this.value_ua,
        },
      ];

      this.filters = filtersArray;
      console.log(this.filters[0].platform);

      window.localStorage.setItem("filters", JSON.stringify(this.filters));

      console.log(JSON.parse(localStorage.filters)[0].platform);

      console.log(this.filters[0]);
      axios
        .post("https://pow.games:5000/", this.filters[0], {
          headers: {
            "Access-Control-Allow-Origin": "*",
            "Content-Type": "application/json",
            "Access-Control-Allow-Headers": "Origin, X-Requested-With, Content-Type, Accept",
          },
        })
        .then((response) => {
          console.log(response);
        });
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.sa[data-v-64363378] {
  height: 100px;
}
.sa-success-tip {
  left: 8px;
  top: 28px;
  transform: rotate(45deg);
  width: 16px;
}
.sa-success-long {
  right: 5px;
  top: 25px;
  transform: rotate(-45deg);
  width: 28px;
}
.sa-success-placeholder {
  width: 50px;
  height: 50px;
}
.sa-success-placeholder {
  width: 50px;
  height: 50px;
}
.sa-success-fix {
  height: 50px;
}

#dialog-content {
  color: #545454;
  font-size: 1.125em;
  font-weight: 400;
  line-height: normal;
  text-align: center;
  word-wrap: break-word;
}
.dialog-header {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.dialog-header sweetalert-icon {
  width: 5em;
  height: 5em;
}
#modal-container-process {
  background-color: rgba(0, 0, 0, 0.6);
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
}
#modal-dialog-process {
  box-sizing: border-box;
  width: 420px;
  padding-top: 20px;
  padding-bottom: 20px;
  color: #343a40;
  background-color: #fff;
  font-size: 1rem;
  border-radius: 0.3125em;
  justify-content: center;
}
#modal-container-process button {
  border-radius: 0.25em;
  border: none;
  margin: 5px;
}
#btn_ok {
  width: 57px;
  height: 28px;
}
#btn_info {
  width: 57px;
  height: 28px;
}
.swal2-container .swal2-popup .swal2-content {
  font-size: 20px;
}
#page_title {
  font-size: 20px;
  text-align: center;
}
label {
  font-size: 14px;
  font-weight: 500;
}
fieldset.scheduler-border {
  border: 1px solid #ddd !important;
  padding: 0 1.4em 1.4em 1.4em !important;
  margin: 0 0 1.5em 0 !important;
  width: 95%;
  min-width: 350px;
  border-radius: 1rem;
}

legend.scheduler-border {
  font-size: 1.2em !important;
  text-align: left !important;
  width: auto;
  padding: 0 10px;
  border-bottom: none;
}
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  /* display: none; <- Crashes Chrome on hover */
  -webkit-appearance: none;
  margin: 0; /* <-- Apparently some margin are still there even though it's hidden */
}

input[type="number"] {
  -moz-appearance: textfield; /* Firefox */
}

.custom_input_div {
  position: relative;
}
.custom_input_div span {
  position: absolute;
  right: 20px;
  top: 36px;
  font-size: 16px;
}
.custom_input {
  height: 43px;
  border-radius: 5px;
  text-align: right;
  padding-right: 20px;
  font-size: 16px;
}
#get_data_button {
  width: 350px;
  height: 40px;
  border-radius: 5rem;
  font-size: 16px;
  margin-top: 20px;
  margin-bottom: 50px;
  outline: none;
  font-family: "NotoSans-Regular";
  padding: 0.375rem 0.75rem;
}
#button_div {
  width: 95%;
}
</style>
