import Vue from "vue";
import VueRouter from "vue-router";

import Homepage from "./components/Homepage"
import Login from "./components/Login";
import Filters from "./components/Filters";
import TableReport from "./components/TableReport";
import GridReport from "./components/GridReport"


Vue.use(VueRouter);
const routes = [
  {
    path: "/login",
    component: Login,
  },
  {
    path: "/crawler",
    component: Homepage,
    children: [
      {
        path: "/filters",
        component: Filters,
      },
      {
        path: "/report",
        component: TableReport,
      },
      {
        path: "/grid_report",
        component: GridReport,
      }
    ]
  },

  { path: "*", redirect: "/login" },
]

export const router = new VueRouter({
  mode: "history",
  routes,
});

router.beforeEach((to, from, next) => {
  const publicPages = ["/login", "/"];
  const authRequired = !publicPages.includes(to.path);
  const isLogin = localStorage.getItem("crawlerLogin");

  if (isLogin == 'true' && !authRequired) {
    return next("/filters");
  } else if ((isLogin == undefined || isLogin == "false") && authRequired) {
    const pathname = window.location.pathname;
    localStorage.pathname = pathname;

    const search = window.location.search;
    localStorage.search = search;

    const path = window.location.href;
    localStorage.path = path;

    return next("/login")
  }

  next();
});