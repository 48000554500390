<template>
  <div class="homepage" id="home">
      <Navbar />
    <router-view class="homepage_content"></router-view>
  </div>
</template>
<script>

import Navbar from './Navbar'
export default {
  data() {
    return {};
  },
    components: {
    Navbar
  },
};
</script>
<style>

</style>
